<!--采购明细-->
<template>
    <div class="InOutDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="业务类型">
                            <el-select v-model="form.bizType">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in bizTypes"
                                    :label="item.value"
                                    :value="item.key"
                                    :key="item.key"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否结账">
                            <el-select placeholder="是否结账" v-model="form.settleStatus">
                                <el-option value="" label="请选择" />
                                <el-option value="0" label="未结账" />
                                <el-option value="1" label="已结账" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="采购单号">
                            <el-input placeholder="单号" v-model="form.bizCode" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.purchase.inOutDetail.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.purchase.inOutDetail.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                id="table"
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="单号" width="160" prop="bizCode" v-if="showColumn('bizCode')" />
                <el-table-column
                    label="机构名称"
                    width="160"
                    prop="deptName"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    label="商品名称"
                    width="200"
                    prop="sku.name"
                    fixed="left"
                    v-if="showColumn('sku.name')"
                />
                <el-table-column
                    label="商品条码"
                    prop="sku.bars"
                    key="sku.bars"
                    width="144"
                    v-if="showColumn('sku.bars')"
                >
                    <template slot-scope="scope">
                        <span v-for="sku in scope.row.sku.bars" :key="sku">{{ sku }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80" prop="sku.unit" v-if="showColumn('sku.unit')" />
                <el-table-column label="规格" width="80" prop="sku.specs" v-if="showColumn('sku.specs')" />
                <el-table-column label="业务类型" width="120" prop="bizType" key="bizType" v-if="showColumn('bizType')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizType | bizTypeName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="160" prop="sku.category" v-if="showColumn('sku.category')" />
                <el-table-column label="品牌" width="160" prop="sku.brand" v-if="showColumn('sku.brand')" />
                <el-table-column label="日期" width="140" prop="date" v-if="showColumn('date')" />
                <el-table-column
                    label="是否结账"
                    width="80"
                    prop="settleStatus"
                    key="settleStatus"
                    v-if="showColumn('settleStatus')"
                >
                    <template slot-scope="scope">
                        {{ scope.row.settleStatus ? '已结账' : '未结账' }}
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购数量"
                    width="120"
                    prop="inCount"
                    :formatter="mCount"
                    v-if="showColumn('inCount')"
                />
                <el-table-column
                    align="right"
                    label="采购成本金额"
                    width="120"
                    prop="inCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('inCostMoney')"
                />
                <el-table-column
                    align="right"
                    label="采购赠品数量"
                    width="120"
                    prop="inGiftCount"
                    :formatter="mCount"
                    v-if="showColumn('inGiftCount')"
                />
                <el-table-column
                    align="right"
                    label="采购赠品成本金额"
                    width="120"
                    prop="inGiftCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('inGiftCostMoney')"
                />
                <el-table-column
                    align="right"
                    label="采购金额"
                    width="120"
                    prop="inMoney"
                    :formatter="mFour"
                    v-if="showColumn('inMoney')"
                />
                <el-table-column
                    align="right"
                    label="退货数量"
                    width="120"
                    prop="outCount"
                    :formatter="mCount"
                    v-if="showColumn('outCount')"
                />
                <el-table-column
                    align="right"
                    label="退货成本金额"
                    width="120"
                    prop="outCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('outCostMoney')"
                />
                <el-table-column
                    align="right"
                    label="退货赠品数量"
                    width="120"
                    prop="outGiftCount"
                    :formatter="mCount"
                    v-if="showColumn('outGiftCount')"
                />
                <el-table-column
                    align="right"
                    label="退货赠品成本金额"
                    width="120"
                    prop="outGiftCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('outGiftCostMoney')"
                />
                <el-table-column
                    align="right"
                    label="退货金额"
                    width="120"
                    prop="outMoney"
                    :formatter="mFour"
                    v-if="showColumn('outMoney')"
                />
                <el-table-column label="出入库操作人" width="120" prop="creatorName" v-if="showColumn('creatorName')" />
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="page.total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from 'js/Util';
import BizTypeUtils from 'js/BizTypeUtils';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'PurchaseInOutDetail',
    components: { EfEndDate, EfStartDate, EfPagination, EfDeptTypeAndDeptSelect, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            emptyZero: true,
            form: {
                deptType: '',
                deptCode: '',
                bizType: '',
                settleStatus: '',
                bizCode: '',
                search: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: 100,
            },
            page: {
                total: 0,
            },
            tableData: [],
            url: {
                page: '/report/purchaseInvoicingDetail',
            },
            bizTypes: [],
        };
    },
    mounted() {
        (async () => {
            this.bizTypes = BizTypeUtils.purchaseTypes(this.form.deptType);
        })();
    },
    methods: {
        handleQuery() {
            Util.queryTable(this, this.url.page, { params: this.form });
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(this, '采购明细表', '/report/purchaseInvoicingDetail/export', this.form);
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
    filters: {
        bizTypeName(type) {
            return BizTypeUtils.name(type);
        },
    },
};
</script>

<style scoped>
.InOutDetail .el-form-item {
    margin-bottom: 0;
}
</style>
